import {
  object as yupObject,
  boolean as yupBoolean,
  string as yupString,
  ValidationError as yupValidationError,
} from "yup"

//Delete once V1 forms dropped WEB TO CASE FORM FIELD IDS
export const WEB_TO_CASE_FORM_FIELD_IDS =
  process.env.GATBSY_CONTACT_FORM_ENVIRONMENT === "STAGING"
    ? {
        orgid: "00D8E000000IEFm",
        email: "email",
        phone: "phone",
        yocoGo: "00N7a00000A1agQ",
        yocoKhumo: "00N7a00000A1agf",
        yocoKhumoPrint: "00N7a00000CHcdS",
        yocoNeo: "00N7a00000A1agk",
        yocoNeoTouch: "00N5E00000HXdDF",
        yocoGateway: "00N7a00000A1agp",
        yocoLink: "00N7a00000A1agq",
        yocoInvoices: "00N7a00000A1ah4",
        yocoPointOfSale: "00NJW0000043lCz",
        unsure: "00N7a00000A1ah9",
        origin: "Origin",
        description: "description",
        utmSource: "00N0Y00000SAkYo",
        utmMedium: "00N0Y00000SAkYt",
        utmTerm: "00N0Y00000SAkYy",
        utmCampaign: "00N0Y00000SAkZ3",
        utmContent: "00N0Y00000SAkZ8",
      }
    : {
        orgid: "00D0Y000001LTZW",
        email: "email",
        phone: "phone",
        yocoGo: "00N7U000000FjsN",
        yocoKhumo: "00N7U000000FlGu",
        yocoKhumoPrint: "00N7U000000FlHn",
        yocoNeo: "00N7U000000FlGz",
        yocoNeoTouch: "00N6900000KGr7y",
        yocoGateway: "00N7U000000Fjsh",
        yocoLink: "00N7U000000Fjsm",
        yocoInvoices: "00N7U000000Fjsr",
        yocoPointOfSale: "00NJW0000043lCz",
        unsure: "00N7U000000Fjsw",
        origin: "Origin",
        description: "description",
        utmSource: "00N0Y00000SAkYo",
        utMedium: "00N0Y00000SAkYt",
        utmTerm: "00N0Y00000SAkYy",
        utmCampaign: "00N0Y00000SAkZ3",
        utmContent: "00N0Y00000SAkZ8",
      }

export const WEB_TO_LEAD_FORM_IDS: {
  [key: keyof typeof WEB_TO_LEAD_INITIAL_VALUES]: string
} =
  process.env.GATSBY_CONTACT_FORM_ENVIRONMENT === "STAGING"
    ? {
        //TEMP field to identify origin. remove once issue resolved
        temp_source: "SOURCE_Temp__c",
        oid: process.env.GATSBY_WEB_TO_LEAD_SALESFORCE_OID as string,
        first_name: "first_name",
        last_name: "last_name",
        email: "email",
        phone: "phone",
        company: "company",
        yocoGo: "00N7U000000Fjt1",
        yocoKhumo: "00N7U000000FlH9",
        yocoKhumoPrint: "00N7U000000FlIC",
        yocoNeoTouch: "00N6900000KGr7y",
        yocoNeo: "00N7U000000FlHE",
        yocoGateway: "00N7U000000FjtG",
        yocoLink: "00N7U000000FjtL",
        yocoInvoices: "00N7U000000FjtQ",
        yocoPointOfSale: "00NJW0000043lCz",
        unsure: "00N7U000000FjtV",
        lead_source: "lead_source",
        utmSource: "00N0Y00000SAkYo",
        utmMedium: "00N0Y00000SAkYt",
        utmTerm: "00N0Y00000SAkYy",
        utmCampaign: "00N0Y00000SAkZ3",
        utmContent: "00N0Y00000SAkZ8",
        callOutcome: "Call_Outcome__c",
        turnover: "00N0Y00000MC2aD",
        origin: "Origin",
        intention: "00NSf000002zeqk",
      }
    : {
        //TEMP field to identify origin. remove once issue resolved
        temp_source: "SOURCE_Temp__c",
        oid: process.env.GATSBY_WEB_TO_LEAD_SALESFORCE_OID as string,
        first_name: "first_name",
        last_name: "last_name",
        email: "email",
        phone: "phone",
        company: "company",
        yocoGo: "00N7U000000Fjt1",
        yocoKhumo: "00N7U000000FlH9",
        yocoKhumoPrint: "00N7U000000FlIC",
        yocoNeoTouch: "00N6900000KGr7y",
        yocoNeo: "00N7U000000FlHE",
        yocoGateway: "00N7U000000FjtG",
        yocoLink: "00N7U000000FjtL",
        yocoInvoices: "00N7U000000FjtQ",
        yocoPointOfSale: "00NSf0000038WFW",
        unsure: "00N7U000000FjtV",
        lead_source: "lead_source",
        utmSource: "00N0Y00000SAkYo",
        utmMedium: "00N0Y00000SAkYt",
        utmTerm: "00N0Y00000SAkYy",
        utmCampaign: "00N0Y00000SAkZ3",
        utmContent: "00N0Y00000SAkZ8",
        callOutcome: "Call_Outcome__c",
        turnover: "00N0Y00000MC2aD",
        origin: "Origin",
        intention: "00NSf000002zeqk",
      }

export const WEB_TO_LEAD_FORM_PRODUCTS = [
  WEB_TO_LEAD_FORM_IDS.yocoGo,
  WEB_TO_LEAD_FORM_IDS.yocoKhumo,
  WEB_TO_LEAD_FORM_IDS.yocoKhumoPrint,
  WEB_TO_LEAD_FORM_IDS.yocoNeoTouch,
  WEB_TO_LEAD_FORM_IDS.yocoGateway,
  WEB_TO_LEAD_FORM_IDS.yocoLink,
  WEB_TO_LEAD_FORM_IDS.yocoInvoices,
  WEB_TO_LEAD_FORM_IDS.yocoPointOfSale,
]

export const WEB_TO_LEAD_INITIAL_VALUES: { [key: string]: string | boolean } = {
  [WEB_TO_LEAD_FORM_IDS.first_name]: "",
  [WEB_TO_LEAD_FORM_IDS.last_name]: "",
  [WEB_TO_LEAD_FORM_IDS.email]: "",
  [WEB_TO_LEAD_FORM_IDS.phone]: "",
  [WEB_TO_LEAD_FORM_IDS.company]: "[not provided]",
  [WEB_TO_LEAD_FORM_IDS.yocoGo]: false,
  [WEB_TO_LEAD_FORM_IDS.yocoKhumo]: false,
  [WEB_TO_LEAD_FORM_IDS.yocoKhumoPrint]: false,
  [WEB_TO_LEAD_FORM_IDS.yocoNeoTouch]: false,
  [WEB_TO_LEAD_FORM_IDS.yocoGateway]: false,
  [WEB_TO_LEAD_FORM_IDS.yocoLink]: false,
  [WEB_TO_LEAD_FORM_IDS.yocoInvoices]: false,
  [WEB_TO_LEAD_FORM_IDS.yocoPointOfSale]: false,
  [WEB_TO_LEAD_FORM_IDS.unsure]: false,
  [WEB_TO_LEAD_FORM_IDS.intention]: "",
  [WEB_TO_LEAD_FORM_IDS.lead_source]: "website_callback",
  [WEB_TO_LEAD_FORM_IDS.temp_source]: "yoco_com_sales_form",
  checkboxErrorMessage: "",
  radioErrorMessage: "",
  [WEB_TO_LEAD_FORM_IDS.turnover]: "",
  ...(process.env.GATSBY_RECAPTCHA_ENABLED === "true" && {
    webToLeadCaptcha: false,
  }),
}

export const phoneRegEx = /^(27)[1-8][0-9]{8}$/

export const WEB_TO_LEAD_VALIDATION_SCHEMA = yupObject({
  [WEB_TO_LEAD_FORM_IDS.first_name]: yupString().required(
    "First name required"
  ),
  [WEB_TO_LEAD_FORM_IDS.last_name]: yupString().required("Last name required"),
  [WEB_TO_LEAD_FORM_IDS.phone]: yupString()
    .required("Phone number required")
    .matches(phoneRegEx, "Valid S.A phone number required"),
  [WEB_TO_LEAD_FORM_IDS.email]: yupString()
    .email("Email must be a valid email")
    .required("Email address required"),
  webToLeadCaptcha:
    process.env.GATSBY_RECAPTCHA_ENABLED === "true"
      ? yupBoolean().required().oneOf([true], "Captcha must be completed")
      : yupBoolean(),
  [WEB_TO_LEAD_FORM_IDS.yocoGo]: yupBoolean(),
  [WEB_TO_LEAD_FORM_IDS.yocoKhumo]: yupBoolean(),
  [WEB_TO_LEAD_FORM_IDS.yocoKhumoPrint]: yupBoolean(),
  [WEB_TO_LEAD_FORM_IDS.yocoNeoTouch]: yupBoolean(),
  [WEB_TO_LEAD_FORM_IDS.yocoGateway]: yupBoolean(),
  [WEB_TO_LEAD_FORM_IDS.yocoLink]: yupBoolean(),
  [WEB_TO_LEAD_FORM_IDS.yocoInvoices]: yupBoolean(),
  [WEB_TO_LEAD_FORM_IDS.yocoPointOfSale]: yupBoolean(),
  [WEB_TO_LEAD_FORM_IDS.unsure]: yupBoolean(),
  [WEB_TO_LEAD_FORM_IDS.intention]: yupString().required("Option is required"),
  [WEB_TO_LEAD_FORM_IDS.turnover]: yupString().required(
    "Estimated turnover is required"
  ),
}).test("CheckboxTest", "Check at least one checkbox", (obj) => {
  if (
    obj[WEB_TO_LEAD_FORM_IDS.yocoGo] ||
    obj[WEB_TO_LEAD_FORM_IDS.yocoKhumo] ||
    obj[WEB_TO_LEAD_FORM_IDS.yocoKhumoPrint] ||
    obj[WEB_TO_LEAD_FORM_IDS.yocoNeoTouch] ||
    obj[WEB_TO_LEAD_FORM_IDS.yocoGateway] ||
    obj[WEB_TO_LEAD_FORM_IDS.yocoLink] ||
    obj[WEB_TO_LEAD_FORM_IDS.yocoInvoices] ||
    obj[WEB_TO_LEAD_FORM_IDS.yocoPointOfSale] ||
    obj[WEB_TO_LEAD_FORM_IDS.unsure]
  ) {
    return true
  }

  return new yupValidationError(
    "Check at least one checkbox",
    null,
    "checkboxErrorMessage"
  )
})

export const YOCO_INTENTION_OPTIONS = [
  {
    label: "New to card payments",
    value: "new_to_card_payments",
  },
  {
    label: "Switching to Yoco",
    value: "switching_provider",
  },
  {
    label: "Going to use Yoco as a backup",
    value: "backup_or_events",
  },
]

export const ESTIMATED_TURNOVER_OPTIONS = [
  {
    label: "Less than R10 000",
    value: "less than R10,000",
    customRatesEligible: false,
  },
  {
    label: "R10 000 - R50 000",
    value: "R10,000 - R50,000",
    customRatesEligible: false,
  },
  {
    label: "R50 000 - R100 000",
    value: "R50,000 - R100,000",
    customRatesEligible: false,
  },
  {
    label: "R100 000 - R200 000",
    value: "R100,000 - R200,000",
    customRatesEligible: false,
  },
  {
    label: "R200 000 - R500 000",
    value: "R200,000 - R500,000",
    customRatesEligible: false,
  },
  {
    label: "R500 000 - R1 000 000",
    value: "R500,000 - R1,000,000",
    customRatesEligible: false,
  },
  {
    label: "R1 000 000 +",
    value: "R1,000,000 +",
    customRatesEligible: false,
  },
]

export const IMAGE_URL = {
  yocoGo: "https://a.storyblok.com/f/111633/200x150/362d8c0501/go.png",
  yocoKhumo: "https://a.storyblok.com/f/111633/216x150/7e7c3a60cd/khumo.png",
  yocoKhumoPrint:
    "https://a.storyblok.com/f/111633/198x150/0fbe0f4fed/khumo_print.png",
  yocoNeo:
    "https://a.storyblok.com/f/111633/225x220/21610c1462/neo-checkbox.png",
  yocoNeoTouch:
    "https://a.storyblok.com/f/111633/194x146/5d34128f48/neo_touch.png",
  yocoInvoices:
    "https://a.storyblok.com/f/111633/200x150/988452655b/invoices.png",
  yocoPointOfSale:
    "https://a.storyblok.com/f/111633/200x150/2edab6b122/point-of-sale.png",
  yocoLink: "https://a.storyblok.com/f/111633/200x150/daf9f57a33/links.png",
  yocoGateway:
    "https://a.storyblok.com/f/111633/200x150/1776ccd934/gateway.png",
}

export const WEB_TO_CASE_INITIAL_VALUES = {
  [WEB_TO_CASE_FORM_FIELD_IDS.email]: "",
  [WEB_TO_CASE_FORM_FIELD_IDS.phone]: "",
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoGo]: false,
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoKhumo]: false,
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoKhumoPrint]: false,
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoNeoTouch]: false,
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoGateway]: false,
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoLink]: false,
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoInvoices]: false,
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoPointOfSale]: false,
  [WEB_TO_CASE_FORM_FIELD_IDS.unsure]: false,
  [WEB_TO_CASE_FORM_FIELD_IDS.origin]: "website_callback",
  [WEB_TO_CASE_FORM_FIELD_IDS.description]: "",
  checkboxErrorMessage: "",
}

export const WEB_TO_CASE_VALIDATION_SCHEMA = yupObject({
  [WEB_TO_CASE_FORM_FIELD_IDS.email]: yupString()
    .email("Email must be a valid email")
    .required("Email address required"),
  [WEB_TO_CASE_FORM_FIELD_IDS.phone]: yupString()
    .required("Phone number required")
    .matches(phoneRegEx, "Valid S.A phone number required"),
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoGo]: yupBoolean(),
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoKhumo]: yupBoolean(),
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoKhumoPrint]: yupBoolean(),
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoNeoTouch]: yupBoolean(),
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoGateway]: yupBoolean(),
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoLink]: yupBoolean(),
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoInvoices]: yupBoolean(),
  [WEB_TO_CASE_FORM_FIELD_IDS.yocoPointOfSale]: yupBoolean(),
  [WEB_TO_CASE_FORM_FIELD_IDS.unsure]: yupBoolean(),
  [WEB_TO_CASE_FORM_FIELD_IDS.description]: yupString(),
}).test("CheckboxTest", "Check at least one checkbox", (obj) => {
  if (
    obj[WEB_TO_CASE_FORM_FIELD_IDS.yocoGo] ||
    obj[WEB_TO_CASE_FORM_FIELD_IDS.yocoKhumo] ||
    obj[WEB_TO_CASE_FORM_FIELD_IDS.yocoKhumoPrint] ||
    obj[WEB_TO_CASE_FORM_FIELD_IDS.yocoNeoTouch] ||
    obj[WEB_TO_CASE_FORM_FIELD_IDS.yocoGateway] ||
    obj[WEB_TO_CASE_FORM_FIELD_IDS.yocoLink] ||
    obj[WEB_TO_CASE_FORM_FIELD_IDS.yocoInvoices] ||
    obj[WEB_TO_CASE_FORM_FIELD_IDS.yocoPointOfSale] ||
    obj[WEB_TO_CASE_FORM_FIELD_IDS.unsure]
  ) {
    return true
  }

  return new yupValidationError(
    "Check at least one checkbox",
    null,
    "checkboxErrorMessage"
  )
})
