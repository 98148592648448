import { useField } from "formik"
import React, { useCallback, useEffect, useRef } from "react"

import InputError from "./InputError"

type Props = {
  id: string
  className?: string
}

const Captcha = ({ id, className }: Props) => {
  // eslint-disable-next-line -- unused field expected
  const [field, meta, helpers] = useField(id)
  const captchaRef = useRef<HTMLDivElement>(null)

  const handleCaptchaResult = useCallback(() => {
    helpers.setValue(true)
  }, [helpers])

  useEffect(() => {
    if (
      process.env.GATSBY_RECAPTCHA_ENABLED === "true" &&
      typeof window !== "undefined" &&
      window.grecaptcha &&
      window.grecaptcha.render &&
      captchaRef.current
    ) {
      window.grecaptcha.render(id, {
        sitekey: process.env.GATSBY_RECAPTCHA_SITE_KEY,
        callback: handleCaptchaResult,
      })
    }
  }, [id, handleCaptchaResult])

  if (!(process.env.GATSBY_RECAPTCHA_ENABLED === "true")) {
    return null
  }

  return (
    <>
      <div id={id} className={className} ref={captchaRef} />
      {meta.touched && meta.error && <InputError error={meta.error} />}
    </>
  )
}

export default Captcha
