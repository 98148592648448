import {
  WEB_TO_LEAD_FORM_PRODUCTS,
  WEB_TO_LEAD_INITIAL_VALUES,
  WEB_TO_LEAD_FORM_IDS,
} from "../constants/V2/salesForm"

// MARK: used in v2.
export const getWebToCaseFormSelectedProducts = (
  formValues: typeof WEB_TO_LEAD_INITIAL_VALUES
) => {
  return WEB_TO_LEAD_FORM_PRODUCTS.flatMap((id) => {
    if (formValues[id]) {
      return Object.keys(WEB_TO_LEAD_FORM_IDS).find(
        (key) => (WEB_TO_LEAD_FORM_IDS as Record<string, string>)[key] === id
      )
    }
    return []
  })
}
